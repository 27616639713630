import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/core/services/utility.service';
import { AuthService } from '../../services/auth.service';
import { UserOrganizationBranchDto } from 'src/app/models/user-organization-branch-dto';
import { OrganizationService } from '../../services/org-onboarding-service/organization.service';
import { UserBranchRoleAndPrivilege } from '../../model/roles-and-right/branch-role-and-privilage';
import { Router } from '@angular/router';
import { RazorpayService } from '../../services/razorpay-sevice/razorpay.service';

@Component({
  selector: 'branch-selection',
  templateUrl: './branch-selection.component.html',
  styleUrls: ['./branch-selection.component.scss'], // Corrected property name to 'styleUrls'
})
export class BranchSelectionComponent implements OnInit {
  userInfo: any = {};
  userOrganizationBranches: UserOrganizationBranchDto[] = [];
  currentOrg: UserOrganizationBranchDto | null = null; // Marked as nullable for safety

  constructor(
    private authService: AuthService,
    private organizationService: OrganizationService,
    private utilityService: UtilityService,
    private router: Router,
    private razorpayService: RazorpayService
  ) {}

  ngOnInit() {
    this.userInfo = UtilityService.getLocalStorage('user_info')
      ? JSON.parse(UtilityService.getLocalStorage('user_info'))
      : {};
    this.getOrganizationBranches();
    this.fetchBranchRolesAndRights();
  }

  getOrganizationBranches() {
    if (!this.userInfo?.id) {
      console.error('User info not available.');
      return;
    }

    const params = { userId: this.userInfo.id };

    this.authService.getOrganizationBranches(params).subscribe(
      (response) => {
        this.userOrganizationBranches = response.body?.data || [];

        const storedBranchId = localStorage.getItem('organizationBranchId');
        if (storedBranchId) {
          this.currentOrg =
            this.userOrganizationBranches.find(
              (branch) => branch.organizationBranchId === storedBranchId
            ) || this.userOrganizationBranches[0];
        }
      },
      (error) => {
        console.error('Error fetching branches:', error);
      }
    );
  }

  onBranchSwitch(event: any) {
    const selectedBranchId = event?.value?.organizationBranchId;
    if (selectedBranchId) {
      this.updateLocalStorage(selectedBranchId);
    }
  }

  private updateLocalStorage(branchId: string | undefined) {
    if (branchId) {
      localStorage.setItem('organizationBranchId', branchId);
      this.razorpayService.GetSubscriptionDetails(this.userInfo.id,branchId).subscribe(
        (response)=>{
          console.log(response)
          if(response.body.status==='authenticated' || response.body.status==='active'){
            localStorage.setItem('subscriptionStatus','active');
          }else{
            localStorage.setItem('subscriptionStatus','inactive');
          }
          this.goToDashboardAndReload();
        },
        (error)=>{
          console.log(error);
          localStorage.setItem('subscriptionStatus','error');
        }
      )
    }
  }

  private goToDashboardAndReload() {
    this.utilityService.setOrganizationRolesAndRights([]);
    location.assign('/dashboard/doctor');
  }

  // Get Roles And Rights
  private fetchBranchRolesAndRights() {
    const branchId = UtilityService.getCurrentBranch();

    if (branchId !== null) {
      this.organizationService.getUserBranchRolesAndRights(branchId).subscribe({
        next: (roles) => {
          this.setRolesAndRights(roles);
        },
        error: (error) => {
          console.log(error);
          this.setRolesAndRights([]);
        },
      });
    } else {
      this.setRolesAndRights([]);
    }
  }

  private setRolesAndRights(roles: UserBranchRoleAndPrivilege[]) {
    this.utilityService.setOrganizationRolesAndRights(roles);
  }
}
